import React, { Fragment } from 'react';
import Layout from '../components/layout/layout'
import Fold from '../components/Fold/Fold';
import { graphql } from "gatsby";
import FadeDown from '../components/FadeDown/FadeDown';
import { PostCard } from '../components/PostCard/PostCard';
import CtaSection from '../components/CtaSection/CtaSection';
import PaginationLinks from '../components/PaginationLinks/PaginationLinks';

const postList = ({ data, pageContext }) => {
  const posts = data.allStrapiBlogPost.edges;
  const { currentPage, numberOfPages } = pageContext;

  return (
    <Layout
      pageTitle={`Blog - Behind the code`}
      description="Explore Kickstage's expert insights in our blog. Discover trends, best practices, and innovative solutions. Elevate your tech business with us today."
    >
      <FadeDown fold>
        <Fold
          title={<Fragment><span>Behind</span> the code</Fragment>}
        />
      </FadeDown>


      <section className="post-section">
        <div className="container">
          <div className="gr-12 no-gutter@md">
            <div className="post-section__filter">

            </div>
            <div className="post-section__posts-list">
              {posts.map(({ node }) => (
                <PostCard
                  key={node.id}
                  link={`/blog/${node.slug}`}
                  title={node.title}
                  description={node.description}
                  categories={node.categories.map(category => (
                    `${category.name}`
                  ))}
                  date={node.date}
                  image={node.postImage.localFile.childImageSharp.gatsbyImageData}
                />
              ))}
            </div>
            {numberOfPages > 1 &&
            <div className="post-section__pagination">
              <PaginationLinks
                currentPage={currentPage}
                numberOfPages={numberOfPages}
              />
            </div>
            }
          </div>
        </div>
      </section>

      <CtaSection
        title="Let's Chat"
        label="What Can We Do For You?"
        link="/contact/"
      />
    </Layout>
  )
}

export const postListQuery = graphql`
  query postListQuery($skip: Int!, $limit: Int!) {
    allStrapiBlogPost(sort: {date: DESC}, limit: $limit, skip: $skip) {
      edges {
        node {
          id
          title
          description
          slug
          createdAt(formatString: "MMMM DD,YYYY")
          updatedAt(formatString: "MMMM DD,YYYY")
          publishedAt(formatString: "MMMM DD,YYYY")
          date(formatString: "MMMM DD,YYYY")
          content {
            data {
              content
            }
          }
          postImage {
            name
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          categories {
            name
          }
        }
      }
    }
  }
`;

export default postList;

