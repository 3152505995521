import React from 'react';

import './tag.scss';

export const Tag = ({ children }) => {
    return (

        <span className="tag">
            {children}
        </span>
    );
};