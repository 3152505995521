import React from 'react'
import './pagination.scss';
import { Link } from "gatsby"
import { ChevronRight } from 'react-feather';
import { ChevronLeft } from 'react-feather';

const PaginationLinks = ({ currentPage, numberOfPages }) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numberOfPages
  const previousPage =
    currentPage - 1 === 1 ? '/blog' : '/blog/page/' + (currentPage - 1).toString()
  const nextPage = '/blog/page/' + (currentPage + 1).toString()
  return (
    <div className="pagination" aria-label="Blog navigation">
      {isFirst ? (
        <div className="pagination__item pagination__item--disabled">
          <Link to="/blog">
          </Link>
        </div>
      ) : (
        <div className="pagination__item">
          <Link previous to={previousPage}>
            <ChevronLeft size="16" className="icon" />
          </Link>
        </div>
      )}
      {Array.from({ length: numberOfPages }, (_, i) =>
        currentPage === i + 1 ? (
          <div className="pagination__item active" key={`page-number${i + 1}`}>
            <Link to={`/${i === 0 ? 'blog' : 'blog/page/' + (i + 1)}`}>
              {i + 1}
            </Link>
          </div>
        ) : (
          <div className="pagination__item" key={`page-number${i + 1}`}>
            <Link to={`/${i === 0 ? 'blog' : 'blog/page/' + (i + 1)}`}>
              {i + 1}
            </Link>
          </div>
        )
      )}

      {
        isLast ? (
          <div className="pagination__item pagination__item--disabled">
            <Link next to={nextPage}>

            </Link>
          </div>
        ) : (
          <div className="pagination__item" >
            <Link next to={nextPage}>
              <ChevronRight size="16" className="icon" />
            </Link>
          </div>
        )
      }
    </div>
  )
}

export default PaginationLinks