import React from 'react';
import './post-card.scss';
import { Heading } from '../Heading/Heading';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Tag } from '../Tag/Tag';
import { Calendar } from 'react-feather';
import { navigate } from "gatsby"
import FadeDown from '../FadeDown/FadeDown';
export const PostCard = ({ title, description, image, categories, id, date, link }) => {
  const parsedImage = getImage(image);


  return (
    <div
      className="post-card" id={id}
      onClick={() => navigate(link)}
      role="presentation"
    >
      <FadeDown>
        <div className="post-card__holder">
          <div className="post-card__details">
            <div className="categories">
              {categories.map((tag, index) =>
                <Tag key={tag}>{tag}</Tag>
              )}
            </div>
            <Heading level="h2">{title}</Heading>
            <div className="date-published">
              <Calendar size="24" />
              <span className="date">{date}</span>
            </div>
            <p className="description">{description}</p>
          </div>
          <div
            className="post-card__image"
          >


            <GatsbyImage
              image={parsedImage}
              className="rspimg post-img"
              alt={title}
            />

          </div>
        </div>
      </FadeDown>
    </div >
  );
};